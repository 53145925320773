import React from 'react';
import Main from '../containers/Layout';
import demosPageContent from '../../content/pages/demos.yml';
import DemosContainer from '@/containers/Demos';

export interface DemosPageContent {
  pageTitle: {
    title1: string;
  };
  mainDemo: {
    image: string;
    title: string;
    description: string;
    link: string;
  };
  demos: {
    image: string;
    title: string;
    description: string;
    link: string;
  }[];
}
const Demos: React.FunctionComponent = () => {
  const { pageTitle, mainDemo, demos } =
    demosPageContent as unknown as DemosPageContent;
  return (
    <Main>
      <DemosContainer content={{ pageTitle, mainDemo, demos }} />
    </Main>
  );
};

export default Demos;
