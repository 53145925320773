import * as React from 'react';
import styled from 'styled-components';
import { P } from './Typography';

const Wrapper = styled.div`
  box-shadow: 0px 0px 6px rgba(19, 39, 63, 0.2);
  border-radius: 5px;
  background-color: #ffff;
  min-width: calc(300px - 1rem * 2);
  margin: 0.5rem;
  padding: 1rem 2rem 0 2rem;
  @media (max-width: 1200px) {
    flex-grow: 1;
  }
`;

const Section = styled.section`
  margin-top: 1rem;
`;

const Header = styled.header`
  margin-bottom: 1rem;
`;

const StyledLink = styled.a`
  text-decoration: none;
  color: inherit;
  cursor: pointer;
`;

const CoverImage = styled.img`
  width: 100%;
  margin-bottom: 2rem;
`;

const Title = styled.a`
  font-weight: bold;
  font-size: 15px;
  text-decoration: none;
  color: black;
  cursor: pointer;
`;

const Description = styled(P)`
  /* margin: 2rem 0; */
`;

const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid #dbe3eb;
  padding: 1.5rem 0;
  margin-top: 1rem;
`;

const ReadMoreLink = styled.a`
  font-weight: 600;
  font-size: 14px;
  text-decoration: none;
  color: #13273f;
  cursor: pointer;
`;

interface DemoCardProps {
  image: string;
  title: string;
  description: string;
  link: string;
}

const DemoCard: React.FunctionComponent<DemoCardProps> = ({
  image,
  title,
  description,
  link,
}) => (
  <Wrapper>
    <StyledLink href={link} target="_blank">
      <article>
        <Header>
          {image && <CoverImage src={image} alt="cover" />}
          <p>
            <Title href={link} target="_blank">
              <span itemProp="headline">{title}</span>
            </Title>
          </p>
        </Header>
        <Section>
          {description && <Description>{description}</Description>}

          <Footer>
            <ReadMoreLink href={link} target="_blank">
              Try it now &rarr;
            </ReadMoreLink>
          </Footer>
        </Section>
      </article>
    </StyledLink>
  </Wrapper>
);

export default DemoCard;
