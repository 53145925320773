/* eslint-disable no-nested-ternary */
import React from 'react';
import styled from 'styled-components';
import { Subtitle1 } from '@/components/Typography';
import Section from '@/components/Section';
import { DemosPageContent } from '@/pages/demos';
import DemoCard from '@/components/DemoCard';

const Content = styled(Section)``;

const StyledSubtitle1 = styled(Subtitle1)`
  text-align: center;
  margin-bottom: 6rem;
`;

const Demos = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  flex-wrap: wrap;
  margin-top: 3rem;
`;

interface DemosPageProps {
  content: DemosPageContent;
}

const DemosContainer: React.FunctionComponent<DemosPageProps> = ({
  content: { pageTitle, mainDemo, demos },
}) => (
  <Content>
    <StyledSubtitle1>{pageTitle.title1}</StyledSubtitle1>
    <div>
      <DemoCard
        image={mainDemo.image}
        title={mainDemo.title}
        description={mainDemo.description}
        link={mainDemo.link}
      />
    </div>
    <Demos>
      {demos.map((el) => (
        <DemoCard
          key={el.title}
          image={el.image}
          title={el.title}
          description={el.description}
          link={el.link}
        />
      ))}
    </Demos>
  </Content>
);

export default DemosContainer;
